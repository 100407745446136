import React from "react"
import { Link,graphql } from "gatsby"

import Layout from "./../components/layout/layout.js"
import { css } from "@emotion/react"
import Seo from "./../components/seo"

const filterNodes = (nodes, arch_name) => {
    return nodes.edges.filter( val => val.node.frontmatter.archive === arch_name)
}

const BlogListPage = (props) => {
    const data = props.data
    const name = props.pageContext.pub_name;    
    const nodes = filterNodes(data.allMdx, name);
    const total_blogs = nodes.length
    
    return (
    <Layout>
        <Seo title={`Computer Vision - ${name}`}
			keywords={['Image Matting','Ganiyu AJ Ibraheem']}/>
        <h1 css={css`margin-top: 2rem;`}>
            {name}
        </h1>
        <div className="grid md:grid-cols-2 md:gap-4" css={css`
				background-color: rgba(236, 240, 241, 1);
                margin-top: 2rem;
                min-height: 25rem;
        `}>
            <div className="md:border-r-2 text-left" css={css`
                padding: 4rem 2rem;
            `}>
                <h5 className="text-muted">EXPLORE THE ARCHIVE</h5>
                <small css={css`display: block;`}>
                    {total_blogs} issue(s)
                </small>
            </div>
            <div className="col-md-8" css={css`
                text-align: left;
                padding-top: 2rem;
            `}>                
                {nodes.map(({node}, idx) => (
                    <div key={node.id}> 
                        #{idx + 1}                       
                        &emsp;
                        <Link
                            className="link link-primary"
                            to={node.fields.pathName} css={css``}>
                            {" "} {node.frontmatter.title}
                        </Link> 
                        &emsp;                        
                        <small className="text-muted text-xs">{node.timeToRead} min read</small>
                    </div>
                ))}
            </div>
        </div>
    </Layout>)
}

export default BlogListPage
export const query = graphql`
    query {
        allMdx (sort: { fields: [frontmatter___date], order: DESC }) {
            totalCount
            edges {
                node {
                    id
                    fileAbsolutePath
                    headings {
                        value
                        depth
                    }
                    frontmatter {
                        title
                        date
                        archive
                    }
                    excerpt
                    timeToRead
                    fields {
                        pathName
                    }
                }
            }
        }
    } 
`